import React from 'react';
import Page from '../components/Page';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import useStoryblok from '../utils/storyblok';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function PageIndex({ data }) {
  const story = useStoryblok(data.story);
  const AllLanguages = data.languages.edges.map((edge) => ({
    code: edge.node.name,
    label: edge.node.value,
  }));
  const allLinks = data.links.nodes;

  if (!story.content) {
    return null;
  }

  return (
    <Layout>
      <Page
        blok={story.content}
        language={story.lang}
        languages={AllLanguages}
        slug={story.slug}
        slugs={story.translated_slugs}
        links={allLinks}
        popups={[]}
      />
    </Layout>
  );
}

export const query = graphql`
  {
    story: storyblokEntry(full_slug: { eq: "gastronomie" }) {
      id
      name
      slug
      field_component
      full_slug
      uuid
      lang
      translated_slugs {
        lang
        path
      }
      content
    }

    languages: allStoryblokDatasourceEntry(
      filter: { data_source: { eq: "languages" } }
    ) {
      edges {
        node {
          name
          value
        }
      }
    }

    links: allStoryblokLink {
      nodes {
        id
        uuid
        name
        slug
        real_path
        alternates {
          lang
          path
        }
      }
    }
  }
`;
